import React from "react";
import Portrait from "./Portrait";

export default function Sighting({ src, sighting, cardSize }) {
  return (
    <div className={`sighting-wrapper`}>
      {!src ? (
        <div class="lds-ellipsis">
          <div>🦤</div>
          <div>🦤</div>
          <div>🦤</div>
          <div>🦤</div>
        </div>
      ) : (
        <a
          href={`https://ebird.org/species/${sighting.speciesCode}`}
          target="_blank"
        >
          <Portrait sciName={sighting.sciName} src={src} />
        </a>
      )}
      <div className="sighting-bottom">
        <div className="name-wrapper">
          <div className="com-name">
            <a
              href={`https://ebird.org/species/${sighting.speciesCode}`}
              target="_blank"
            >
              {sighting.comName}
            </a>{" "}
          </div>
          <div className="sci-name">
            {sighting.sciName}{" "}
            <span className="speciesCode">({sighting.speciesCode})</span>
          </div>
        </div>

        <div className="location-wrapper">
          <a
            href={`https://www.latlong.net/c/?lat=${sighting.lat}&long=${sighting.lng}`}
            className="location-name"
            target="_blank"
          >
            {sighting.howMany !== undefined && (
              <div className="number">x{sighting.howMany}</div>
            )}
            <div className="location-name">@ {sighting.locName}</div>
          </a>
        </div>
      </div>
    </div>
  );
}
