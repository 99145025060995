import React from "react";
import { useState, useEffect } from "react";
import BirdAnimation from "./BirdAnimation";
import handleViewport from "react-in-viewport";
import shareLogo from "../resources/gingko-share.png";
import Landing3 from "./Landing3";

export default function Landing2(props) {
  const [animated, setAnimated] = useState(false);
  const [scrollPt, setScrollPt] = useState(0);

  window.addEventListener("scroll", () => {
    // console.log(`entered: ${entered}`);
    setScrollPt(window.scrollY);
  });
  useEffect(() => {
    if (scrollPt > 50 && !animated) {
      console.log(`scrollPt over 100`);
      const top = document.getElementsByClassName("landing-2-top")[0];
      top.style.opacity = 1;
      // top.style.paddingLeft = "25px";

      setAnimated(true);
    }
  });

  return (
    <div className="landing-2-wrapper">
      <div className="landing-2-top">
        <BirdAnimation />

        <div className="globe-text">
          <div className="left-2-main-text">New sightings, everyday.</div>
          <div className="left-2-second-text">
            More than 100 million sightings a year are contributed to the eBird
            database powering Ginkgo, from backyards in Mozambique to ships on
            the High Seas.
          </div>
        </div>
      </div>
      <Landing3 />
    </div>
  );
}
