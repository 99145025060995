import React from "react";

export default function Portrait({ sciName, src }) {
  return (
    <div className="portrait-wrapper">
      {src !== "https://" ? (
        <img className="portrait" src={src} />
      ) : (
        <div className="portrait no-img" src={src}>
          <p>🦤</p>
        </div>
      )}
    </div>
  );
}
