import React from "react";
import { useRef, useState, useEffect } from "react";
import birdlogo from "../resources/birdlogo-color.png";
import gitLogo from "../resources/GitHub-Mark-32px.png";
import discordLogo from "../resources/discord-mark-white.svg";
import Landing2 from "./Landing2";
import Landing4 from "./Landing4";

export default function Landing() {
  return (
    <div id="landing-wrapper">
      <div className="landing-1">
        <div className="logo-container">
          <img className="logo" src={birdlogo} height="500px" width="400px" />
        </div>
        <div className="landing-text">
          <p>
            Hi, I'm <b>Ginkgo</b>,
          </p>
          <div className="landing-bottom-text">
            <p>a Discord bot for</p>
            <p>birdwatchers.</p>
          </div>
          <div className="buttons">
            <button
              className="landing-btn"
              type="button"
              onClick={() =>
                (window.location.href = "https://github.com/jajego/birdbot")
              }
            >
              <img className="git-logo" src={gitLogo} height="20" width="20" />
              Documentation
            </button>
            <button
              className="landing-btn"
              type="button"
              onClick={() =>
                (window.location.href =
                  "https://discord.com/api/oauth2/authorize?client_id=1037093121357520976&permissions=534723950656&scope=bot%20applications.commands")
              }
            >
              {" "}
              <img
                className="discord-logo"
                src={discordLogo}
                height="20"
                width="20"
              />
              Invite to your server
            </button>
          </div>
        </div>
      </div>
      <Landing2 />
    </div>
  );
}
