import React from "react";
import { useRef, useState, useEffect } from "react";
import birdlogo from "../resources/birdlogo-color.png";
import MapIcon from "./MapIcon";
import CoordsIcon from "./CoordsIcon";
import TaxIcon from "./TaxIcon";

export default function Landing3() {
  const [animated3, setAnimated3] = useState(false);
  const [scrollPt3, setScrollPt3] = useState(0);

  window.addEventListener("scroll", () => {
    // console.log(`entered: ${entered}`);
    setScrollPt3(window.scrollY);
  });
  useEffect(() => {
    if (scrollPt3 > 300 && !animated3) {
      console.log(`scrollPt3 over 1300`);
      const col1 = document.getElementsByClassName("landing-3-circle-1")[0];
      col1.style.opacity = 1;
      col1.style.paddingBottom = "150px";

      const col3 = document.getElementsByClassName("landing-3-circle-3")[0];
      col3.style.opacity = 1;
      col3.style.paddingBottom = "150px";
      setTimeout(() => {
        const col2 = document.getElementsByClassName("landing-3-circle-2")[0];
        col2.style.opacity = 1;
        col2.style.paddingBottom = "150px";

        const col4 = document.getElementsByClassName("landing-3-circle-4")[0];
        col4.style.opacity = 1;
        col4.style.paddingBottom = "150px";
      }, 250);

      setAnimated3(true);
    }
  });
  return (
    <div className="landing-3">
      <div className="landing-3-circle-1">
        <div class="cube">
          <div class="face front">🗺️</div>
          <div class="face side">🗺️</div>
          <div class="face top">🗺️</div>
        </div>
        <div className="icon-text region-text-header">/region</div>
        <div className="region-text-wrapper">
          <div className="region-text">
            eBird has a global presence, so Ginkgo can generate reports for 309
            different countries and regions, including every US state and
            Canadian province.
          </div>
        </div>
      </div>
      <div className="landing-3-circle-2">
        <div class="cube">
          <div class="face front">🦤</div>
          <div class="face side">🦤</div>
          <div class="face top">🦤</div>
        </div>

        <div className="icon-text species-text-header">/species</div>
        <div className="species-text-wrapper">
          <div className="species-text">
            Interested in a specific species? Ginkgo can tell you recent
            sightings for over 10,000 different birds using eBird's taxonomy
            system. Learn more
            <a href="https://science.ebird.org/en/use-ebird-data/the-ebird-taxonomy">
              {" "}
              here.
            </a>
          </div>
        </div>
      </div>
      <div className="landing-3-circle-3">
        <div class="cube">
          <div class="face front">📍</div>
          <div class="face side">📍</div>
          <div class="face top">📍</div>
        </div>
        <div className="icon-text coords-text-header">/coords</div>
        <div className="coords-text-wrapper">
          <div className="coords-text">
            Ginkgo can look for birds within 50 kilometers of any spot on the
            planet—all it needs is a latitude and longitude.
          </div>
        </div>
      </div>
      {/* <div className="landing-3-circle-4">
        <div class="cube">
          <div class="face front">❓</div>
          <div class="face side">❓</div>
          <div class="face top">❓</div>
        </div>
        <div className="icon-text coords-text-header">/random</div>
        <div className="random-text-wrapper">
          <div className="random-text">
            If you want to learn about new birds, you can ask Ginkgo to pick one
            at random.
          </div>
        </div>
      </div> */}
    </div>
  );
}
