import Landing from "./components/Landing";
import Query from "./components/Query";
import { useState, useRef, useEffect } from "react";
import { Routes, Route, BrowserRouter, useParams } from "react-router-dom";

function App() {
  const [cardSize, setCardSize] = useState("default");
  window.onbeforeunload = () => window.scrollTo(0, 0);

  useEffect(() => {
    document.title = "Ginkgo";
  });
  const [skewAmt, setSkewAmt] = useState(0);
  window.addEventListener("wheel", (e) => {
    e.deltaY < 0 ? setSkewAmt(skewAmt + 1) : setSkewAmt(skewAmt - 1);
  });

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/q/:queryId" element={<Query />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
