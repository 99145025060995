import React from "react";
import axios from "axios";
import Sighting from "./Sighting";
import { useRef, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  getCache,
  addToCache,
  isInCache,
  findInCache,
} from "../services/cache";
import logo from "../resources/ginkgo-discord-icon.png";

export default function Query() {
  const { queryId } = useParams();
  const [sightings, setSightings] = useState([]);
  const [queryDate, setQueryDate] = useState("");
  const [queryContent, setQueryContent] = useState("");
  const [birdNames, setBirdNames] = useState([]);
  const [links, setLinks] = useState([]);
  const sightingsRef = useRef();
  const [cardSize, setCardSize] = useState("460");
  const [querying, setQuerying] = useState(false);
  const [queryCounter, setQueryCounter] = useState(0);

  // sightingsRef.current &&
  //   sightingsRef.current.addEventListener("wheel", (e) => {
  //     e.preventDefault();

  //     sightingsRef.current.scrollBy({
  //       left: e.deltaY < 0 ? -3 : 3,
  //     });
  //   });

  useEffect(() => {
    fetch(`https://db.ginkgo.page/query/${queryId}/`, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        let birdNames = [];
        setSightings(JSON.parse(data.rows[0].sightings));
        JSON.parse(data.rows[0].sightings).map((sighting) =>
          birdNames.push(sighting.speciesCode)
        );
        setBirdNames(birdNames);
        setQueryDate(data.rows[0].created);
        setQueryContent(data.rows[0].queryContent);
      });
  }, []);

  useEffect(() => {
    if (isInCache(queryId)) {
      let cachedLinks = findInCache(queryId)["links"];
      setLinks(cachedLinks);
      return;
    }
    if (birdNames.length > 0 && !querying) {
      setQuerying(true);
      setQueryCounter(queryCounter + 1);

      getUrls(birdNames).then((response) => {
        if (response.data.length > 0) {
          setLinks(response.data);
          setQuerying(false);
        }
        if (response.data.length > 0) {
          let linkObj = { queryId: queryId, links: response.data };
        }
      });
    }
  }, [birdNames]);

  useEffect(() => {
    let cache = getCache();
    if (isInCache(queryId)) {
      return;
    } else {
      if (links.length > 0) {
        addToCache({ queryId: queryId, links: links });
      }
    }
  }, [links]);

  const getUrls = async (birdNames) => {
    try {
      setQuerying(true);
      const response = axios.request({
        method: "get",
        url: "https://scraper.ginkgo.page/",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST, GET",
          "Content-Type": "text/plain",
        },
        timeout: 300000,
        params: {
          birds: [birdNames],
        },
      });
      return response;
    } catch (e) {
      console.log(`Erroring out :(`);
      console.log(e);
    }
  };

  return (
    <div className="sightings-wrapper">
      <div className="sightings-header">
        <div className="ginkgo-wrapper">
          <img className="header-logo" src={logo} />

          <div className="ginkgo">
            <a href="https://ginkgo.page">Ginkgo</a>
          </div>
        </div>

        <div className="query-location">{queryContent}</div>
        <div className="query-date">{queryDate.substring(0, 10)}</div>
      </div>

      <div ref={sightingsRef} className="sightings">
        {sightings.map((sighting, index) => (
          <Sighting
            src={links[index]}
            sighting={sighting}
            cardSize={cardSize}
          />
        ))}
      </div>
      <div className="citation">
        Image source: Macaulay Library (Cornell Lab of Ornithology)
      </div>
    </div>
  );
}
