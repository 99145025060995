import React from "react";

export default function BirdAnimation() {
  return (
    <div class="pic-ctn">
      <img
        src="https://upload.wikimedia.org/wikipedia/commons/3/3c/Acridotheres_tristis00.jpg"
        alt=""
        class="pic"
      />
      <img
        src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/ac/Trachyphonus_vaillantii_-near_Sand_River_Selous%2C_Selous_Game_Reserve%2C_Tanzania-8.jpg/800px-Trachyphonus_vaillantii_-near_Sand_River_Selous%2C_Selous_Game_Reserve%2C_Tanzania-8.jpg"
        alt=""
        class="pic"
      />
      <img
        src="https://inaturalist-open-data.s3.amazonaws.com/photos/104619954/large.jpeg"
        alt=""
        class="pic"
      />
      <img
        src="https://upload.wikimedia.org/wikipedia/commons/e/e4/Fregata_magnificens_-Galapagos%2C_Ecuador_-male-8_%281%29.jpg"
        alt=""
        class="pic"
      />
      <img
        src="https://upload.wikimedia.org/wikipedia/commons/thumb/4/45/Eopsaltria_australis_-_Mogo_Campground.jpg/2880px-Eopsaltria_australis_-_Mogo_Campground.jpg"
        alt=""
        class="pic"
      />
    </div>
  );
}
