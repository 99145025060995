const getCache = () => {
  let cache = JSON.parse(localStorage.getItem("cache"));
  if (cache) {
    return cache;
  }
  return [];
};

const addToCache = (obj) => {
  let cache = getCache();
  cache.push(obj);
  localStorage.setItem("cache", JSON.stringify(cache));
};

const findInCache = (queryId) => {
  let cache = getCache();
  return cache.find((query) => query.queryId == queryId);
};
const isInCache = (queryId) => {
  let cache = JSON.parse(localStorage.getItem("cache"));
  if (cache && cache.find((query) => query.queryId == queryId)) {
    return true;
  }

  return false;
};

module.exports = {
  getCache,
  addToCache,
  findInCache,
  isInCache,
};
